import React from 'react'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
// import PortableText from '../../serializers/portableText'
import styles from './portfolio.module.css'

const Portfolio = ({projects}) => {
  // console.log({projects})

  const ProjectImage = ({project}) => {
    let pImage = ''
    if (project.asset) {
      pImage = <div className={styles.projectImage}><Img fluid={project.asset.fluid} alt={project.alt} /> </div>
    } else if (project.slides[0]) {
      pImage = <div className={styles.projectImage}><Img fluid={project.slides[0].slideImage.asset.fluid} alt={project.slides[0].alt} /> </div>
    }
    return pImage
  }
  return (
    <>
      <div className={styles.portfolioWrapper}>
        {projects.map(project => (
          <Link to={`/portfolio/${project.slug.current}`} key={project._id}>
            <div className={styles.projectWrapper} title={`link to ${project.title}`}>

              <div className={styles.projectContentWrapper}>
                <h3>{project.title}</h3>
                <p>{project.location}</p>
              </div>

              {project.hero[0] && (<ProjectImage project={project.hero[0]} />)}

            </div>
          </Link>
        ))}
      </div>

    </>

  )
}
export default Portfolio
