import React from 'react'

import Portfolio from './portfolio'
import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const PortfolioPage = ({title, _rawBody, projects}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>

          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          {/* Portfolio */}
          <Portfolio projects={projects} />

        </Container>
      </article>
    </>
  )
}
export default PortfolioPage
