import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/page/portfolioPage/index'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityPortfolioPage(slug: { current: { eq: $slug } }) {
      title
      _rawBody
      hero {
        __typename
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 1900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 1900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          videoType
          videoID
        }
      }
      projects {
        _id
        title
        location
        hero {
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 590, maxHeight: 393) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 590, maxHeight: 393) {
                ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        }
        slug {
          current
        }
      }
      seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      url
            }
         }
      }
    }
  }
`

const PortfolioPage = ({data: {sanityPortfolioPage: page}}) => (
  <Layout hero={page.hero} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <Page
      title={page.title}
      _rawBody={page._rawBody}
      projects={page.projects}
    />
  </Layout>
)

export default PortfolioPage
